<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 20 20" fill="none">
        <path d="M11.3193 17.125C12.8223 15.2441 16.25 10.6855 16.25 8.125C16.25 5.01953 13.7305 2.5 10.625 2.5C7.51953 2.5 5 5.01953 5 8.125C5 10.6855 8.42773 15.2441 9.93066 17.125C10.291 17.5732 10.959 17.5732 11.3193 17.125ZM10.625 10C9.59082 10 8.75 9.15918 8.75 8.125C8.75 7.09082 9.59082 6.25 10.625 6.25C11.6592 6.25 12.5 7.09082 12.5 8.125C12.5 9.15918 11.6592 10 10.625 10Z" fill="#2F80ED"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>