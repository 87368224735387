<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M3.89453 11.3008L9.18848 16.2431C9.4082 16.4482 9.69824 16.5625 10 16.5625C10.3018 16.5625 10.5918 16.4482 10.8115 16.2431L16.1055 11.3008C16.9961 10.4717 17.5 9.30857 17.5 8.09275V7.92283C17.5 5.87497 16.0205 4.12888 14.002 3.79197C12.666 3.56931 11.3066 4.00583 10.3516 4.96091L10 5.31247L9.64844 4.96091C8.69336 4.00583 7.33398 3.56931 5.99805 3.79197C3.97949 4.12888 2.5 5.87497 2.5 7.92283V8.09275C2.5 9.30857 3.00391 10.4717 3.89453 11.3008Z" fill="#EB5757"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if(props.fill){
                return props.fill
            }
            
            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }

@media screen and (min-width: 600px) {
//For Tablets
    .container {
        width: 100;
    }
}

@media screen and (min-width: 768px) {
//For Laptops
    .container {
        width: 738px;
    }
}

@media screen and (min-width: 992px) {
//For Large Laptops
    .container {
        width: 962px;
    }
}

@media screen and (min-width: 1280px) {
//For Big TV's (HD Screens) 
    .container {
        width: 1250px;
    }
}


@media screen and (min-width: 1920px) {
//For Projectors or Higher Resolution Screens (Full HD)
    .container {
        width: 1890px;
    }
}
@media screen and (min-width: 3840px) {
//For 4K Displays (Ultra HD)
    .container {
        width: 3810px;
    }
}
</style>