<template>
    <div class="left">
        <div class="flex align-items-center gap-10" style="min-width: 135px;">
            <img class="img-profile" :src="obj.url" alt="" />
            <b>{{ obj.name }}</b>
            <span class="description">{{ obj.description }}</span>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";

export default {
    props: ["obj"],
    // components: {
    // },
    setup() {

        return {

        }
    }
}
</script>

<style lang="scss" scoped>
h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #BDBDBD;
    margin: 0;
    padding: 0;
}

b {
    color: var(--gray-3, #828282);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.description {
    color: var(--gray-4, #BDBDBD);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.left {
    display: flex;
    align-items: center;
    width: max-content;
    gap: 32px;

    .img-profile {
        width: 40px;
        height: 40px;
        border-radius: 10px;
    }
}
</style>