<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M5.71875 5.17031C5.71875 4.17891 6.52266 3.375 7.51406 3.375C7.98984 3.375 8.44688 3.56484 8.78203 3.9L9 4.11797L9.21797 3.9C9.55312 3.56484 10.0102 3.375 10.4859 3.375C11.4773 3.375 12.2812 4.17891 12.2812 5.17031C12.2812 5.64609 12.0914 6.10312 11.7563 6.43828L9.33047 8.86172C9.14766 9.04453 8.85 9.04453 8.66719 8.86172L6.24375 6.43828C5.90859 6.10312 5.71875 5.64609 5.71875 5.17031ZM15.5672 11.257C15.8742 11.6742 15.7852 12.2602 15.368 12.5672L12.4008 14.7539C11.8523 15.157 11.1914 15.375 10.5094 15.375H6.75H3C2.58516 15.375 2.25 15.0398 2.25 14.625V13.125C2.25 12.7102 2.58516 12.375 3 12.375H3.8625L4.91484 11.5312C5.44687 11.1047 6.10781 10.875 6.78984 10.875H8.625H9H10.5C10.9148 10.875 11.25 11.2102 11.25 11.625C11.25 12.0398 10.9148 12.375 10.5 12.375H9H8.625C8.41875 12.375 8.25 12.5437 8.25 12.75C8.25 12.9563 8.41875 13.125 8.625 13.125H11.4516L14.257 11.0578C14.6742 10.7508 15.2602 10.8398 15.5672 11.257ZM6.7875 12.375H6.76641C6.77344 12.375 6.78047 12.375 6.7875 12.375Z" fill="#4F4F4F"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>