<template>
    <div class="informations">
        <div class="left-container">
            <Biography :bio="item.informations.biography" />
            <div class="professions">
                <Professions :professions="item.informations.profissions" />
                <Speciality :specialties="item.informations.specialties" />
            </div>
            <Treatments :treatments="item.informations.treated_diseases" />
        </div>
        <div class="right-container">
            <ServiceLocations :locations="item.informations.locations" />
        </div>
    </div>
</template>

<script>
// import { onMounted, ref } from "vue";
import Biography from "./Biography.vue";
import ServiceLocations from "./ServiceLocations.vue";
import Professions from "./Professions.vue"
import Speciality from "./Speciality.vue"
import Treatments from "./Treatments.vue"


export default {
    props: ["item"],
    components: {
        Biography,
        ServiceLocations,
        Professions,
        Speciality,
        Treatments,
    },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>

.left-container{
    display: flex;
    flex-direction: column;
    width: 100%;

    gap: 10px;
}

.right-container {
    display: flex;
    flex-direction: column;
    width: 100%;

}

.informations {
    display: flex;
    width: 100%;
    background: white;
    margin-top: -4px;
    border-radius: 0 0 16px 16px;
    padding: 12px;
    padding-top: 16px;
    z-index: 1;
    gap: 8px;

    box-shadow: 0px 3px 6px 0px rgba(61, 71, 102, 0.06), 0px 8px 24px 0px rgba(61, 71, 102, 0.08);
}

.professions {
    display: flex;
    flex-direction: row;
    // flex-wrap: wrap;
    gap: 10px;
}

</style>