<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M12.0667 8.26673L7.93334 12.4001C6.8 13.5334 5 13.5334 3.93334 12.4001C2.8 11.2667 2.8 9.46673 3.93334 8.40007L9.26667 3.06673C9.93334 2.46673 10.9333 2.46673 11.6 3.06673C12.2667 3.7334 12.2667 4.80007 11.6 5.40007L7 10.0001C6.8 10.2001 6.46667 10.2001 6.26667 10.0001C6.06667 9.80007 6.06667 9.46673 6.26667 9.26673L9.66667 5.86673C9.93334 5.60007 9.93334 5.20007 9.66667 4.9334C9.4 4.66673 9 4.66673 8.73334 4.9334L5.33334 8.40007C4.6 9.1334 4.6 10.2667 5.33334 11.0001C6.06667 11.6667 7.2 11.6667 7.93334 11.0001L12.5333 6.40007C13.7333 5.20007 13.7333 3.3334 12.5333 2.1334C11.3333 0.933398 9.46667 0.933398 8.26667 2.1334L2.93334 7.46673C2.13334 8.26673 1.73334 9.3334 1.73334 10.4001C1.73334 12.7334 3.6 14.5334 5.93334 14.5334C7.06667 14.5334 8.06667 14.0667 8.86667 13.3334L13 9.20007C13.2667 8.9334 13.2667 8.5334 13 8.26673C12.7333 8.00006 12.3333 8.00006 12.0667 8.26673Z" fill="#4F4F4F"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>