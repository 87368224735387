import image1 from "../../assets/avatares/professionals/anderson.jpeg"
import image2 from "../../assets/avatares/professionals/abelardo-correto.png"
import image3 from "../../assets/avatares/professionals/10.png"
import image4 from "../../assets/avatares/dependents/4.png"
import image5 from "../../assets/avatares/dependents/5.png"
import image6 from "../../assets/avatares/dependents/6.png"
import location1 from "../../assets/images/clinica-moura.png";
import location2 from "../../assets/images/hospital-einsten.png";
import location3 from "../../assets/avatares/locations/3.png";

export const dataFavorites = {
    group: 'Familiares',
    data: [
        {
            url: image1,
            name: "Aderson A. Moura",
            sex: "M",
            description: "Médico cirurgião do aparelho digestivo",
            informations: {
                biography: "Dr. Aderson Aragão é especialista em cirurgia geral, coloproctologia e aparelho digestivo. Oferece serviços de alta qualidade, com técnicas avançadas e personalizadas para cada caso. Com anos de experiência e dedicação, ele atende às necessidades dos pacientes com habilidade, profissionalismo e compromisso. Conte com ele para cuidar da sua saúde.",
                profissions: [
                    {name: "Médico(a)", status: "approved"}
                ],
                specialties: [
                    "Cirurgia geral",
                    "Cirurgia do aparelho digestivo",
                    "...",
                ],
                treated_diseases: [
                    "Obesidade",
                    "Cálculos na vesícula biliar",  
                    "Esteatose hepática",
                    "...",
                ],
                locations: [
                    {avatar: location1, name: "Clínica Moura", address: "Travessa A Banda, Nº 154", km: 399},
                    {avatar: location2, name: "Hospital Albert Einstein", address: "Av. Albert Einstein, Nº 627/701", km: 407},
                ],
                status: true,
                hidden: false,       
            },
            see_profile: "registro",
            action: "marcar"
        },
        {
            url: image2,
            name: "Abelardo V. Filho",
            sex: "M",
            description: "Cardiologista",
            informations: {
                biography: "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua aliqua aliqua aliquateste rubens rubens rubens rubens rubens",
                profissions: [
                    {name: "Médico(a)", status: "approved"},
                ],
                specialties: [
                    "Ginecologia",
                ],
                treated_diseases: [
                    "Candidíase",
                    "Endometriose",
                    "Mioma uterino",
                    "Mioma uterino",
                    "-",
                ],
                locations: [
                    {avatar: location1, name: "Casa Rita Bonfim", address: "Av. Comborja Nilo, Beco Anches, Nº 228", km: 12},
                    {avatar: location2, name: "Clínica Rosaria III", address: "Av. Moanita, Rua Mariana Gomes, Nº 1265", km: 25},
                    {avatar: location3, name: "Clínica Rosaria VI", address: "Rua Noacir Filho, Beco Flores, Nº 25", km: 45},
                ],
                status: true,
                hidden: false,       
            },
            status_service: "registro",
            action: "marcar"
        },
    ]
}