<template>
    <div>
        <Dialog class="modal" :style="{
            width: '461px',
            borderRadius: '8px',
            zIndex: 1000,
        }" :baseZIndex="0" :autoZIndex="false" :visible="visible" @hide="close" @update:visible="onUpdate">

            <template #header>
              <h5 class="m-auto">Marcar consulta</h5>
            </template>

            <div class="item-container">
              <div class="item-image">
                <img src="../../../assets/avatares/professionals/anderson.jpeg" alt="" class="image"/>
              </div>
              <div class="item-infos">
                <div class="item-name">
                  Aderson A. Moura
                </div>
                <div class="item-description">
                  Médico cirurgião do aparelho digestivo
                </div>
              </div>  
            </div>

            <div class="content">
              <!-- <div class="flex flex-column gap-10 mt-16 inputs"> -->
              <div class="flex flex-column gap-10 mt-16">
                <Dropdown
                class="dropdown"
                type="dropdown"
                v-model="selectedService" 
                :options="services" 
                optionLabel="name" 
                placeholder="Selecione o serviço" 
                
                />

                <Dropdown 
                class="dropdown"
                type="dropdown"
                v-model="selectedLocal" 
                :options="locals" 
                optionLabel="name" 
                placeholder="Selecione o local" 
                
                />

                <Calendar 
                class="calendar"
                v-model="value"
                dateFormat="dd/mm/yy"
                :disabledDays="[0,3,4,6]"
                placeholder="Data do atendimento"
                
                />

                <!-- <Calendar 
                class="calendar"
                v-model="value"
                :showTime="true"
                :timeOnly="true"
                placeholder="horário do atendimento"
                /> -->

                <Dropdown
                class="dropdown"
                type="dropdown"
                v-model="selectedHour" 
                :options="Hours" 
                optionLabel="name" 
                placeholder="Selecione a hora" 
                
                />

                 <!-- <div class="data-container">
                      <button class="clock" @click="highlight">
                        <div class="container-clock">
                          <img class="clock-icon" src="../../../assets/icons/clock.png" />
                            <p class="vclock">
                              16:00
                            </p>
                        </div>
                        <p class="time">
                            0:30 min
                        </p>
                      </button>

                      <button class="clock">
                        <div class="container-clock">
                          <img class="clock-icon" src="../../../assets/icons/clock.png" />
                            <p class="vclock">
                                18:00
                            </p>
                        </div>
                        <p class="time">
                            0:30 min
                        </p>
                      </button>
                  </div>  -->

                </div>
            </div>

              <template #footer>
                <div class="flex justify-content-end align-items-center h-full">
                  <Button label="Marcar" class="addService-button" @click="close" />
                </div>
                
            </template>
          </Dialog>
      </div>
</template>  
    
<script>
import { ref, onMounted, watch, reactive } from "vue";
import Calendar from 'primevue/calendar';
import ToggleButton from 'primevue/togglebutton';


export default {
  props: ["visible"],
  emits: ["update:visible"],
  components: {

  },
  setup(props, ctx) {
    // const avatar = ref(null);
    // const color = ref("#EB5757");
    // const name = ref("");
    // const type = ref("");
    // const address = ref({ cep: "", state: "", city: "", address: "", number: "", complement: "", neighborhood: "" })
    // const msgErros = ref({ name: [], type: [], address: [], city: [], neighborhood: [], number: [], postalCode: [], state: [] })
    const selectedService = ref(null);
    const selectedLocal = ref(null);
    const value = ref(null)
    const checked = ref(true);
    const selectedHour = ref(null);


    const Hours = [
      { name: "16:00"},
      { name: "18:00"}
    ];

    const services = [
      { name: "Consulta"},
      { name: "Remoção"}
    ];

    const locals = [
      { name: "Clínica Moura" },
      { name: "Hospital Albert Einstein" },
    ];

    const close = () => {
      ctx.emit("update:visible", false)
      // services[0].name = "";
      // locals[0].name= "";
    }

    const onUpdate = (val) => {
      (!val) && ctx.emit("update:visible", val);
    }

    // const onSubmit = () => {
    //   const obj = {
    //     avatar: avatar.value ? avatar.value.replace(process.env.VUE_APP_BUCKET_URL + '/', "") : "",
    //     color: color.value,
    //     name: name.value,
    //     type: type.value,
    //     address: address.value,
    //   }
    //   AddLocation(obj)
    // }

    return {
      close,
      onUpdate,
      services,
      locals,
      Hours,
      selectedService,
      selectedLocal,
      selectedHour,
      value
      // onSubmit,
      // changeAvatar,
      // avatar,
      // color,
      // name,
      // type,
      // address,
      // msgErros,
    };
  },
};
</script>  

<style lang="scss" scoped>

.item-container {
  display: flex;
  border-radius: 16px;
  padding: 10px 20px;
  margin-top: 20px;
  gap: 10px;

  background: #FAFAFA;
  .item-image {
    display: flex;
    height: 60px;
    width: 60px;
    border-radius: 16px;
    .image {
      border-radius: 16px;
    }
  }
  .item-infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2px;
    .item-name {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #4F4F4F;
    }
    .item-description {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #828282;
    }
  }
}
.content {
  margin-top: 1.5rem;

  .data-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 8px;
    padding: 20px;

    border-style: solid;
    border-width: 1px;
    border-color: #4F4F4F;

    background: #FAFAFA;
    border-color: solid ;

    .clock {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: max-content;
      gap: 6px;

      border-style: solid;
      border-width: 1px;
      border-color: lightgray;
      &:hover {
        border-color: orangered;
      }

      
      cursor: pointer;
      background: white;
      border-radius: 100px;
      padding: 0 8px;
      height: 30px;

      .container-clock{
          display: flex;
          align-items: center;
          gap: 4px;
          border-right: 1px solid #E0E0E0;
          padding-right: 6px;
          .vclock{
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 16px;
              color: #828282;
          }
        }
      .time{
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #BDBDBD;
      }
    }
  }
}


::v-deep(.p-dropdown) {
  display: flex;
  align-items: center;
  border: 1px solid #F2F2F2;
  border-radius: 8px;
  height: 45px;

  &::placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #828282;
  }
}

::v-deep(.p-calendar) {
  cursor: pointer;
}

::v-deep(input) {
  border: 1px solid #F2F2F2;
  border-radius: 8px;
  height: 45px;

  &::placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #828282;
  }
}

::v-deep(.p-dialog) {
  z-index: 2300 !important;
}

</style>