<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path d="M7.00002 1.16675C3.79169 1.16675 1.16669 3.79175 1.16669 7.00008C1.16669 10.2084 3.79169 12.8334 7.00002 12.8334C10.2084 12.8334 12.8334 10.2084 12.8334 7.00008C12.8334 3.79175 10.2084 1.16675 7.00002 1.16675ZM9.45002 6.00841L6.65002 8.80841C6.41669 9.04175 6.06669 9.04175 5.83335 8.80841L4.55002 7.52508C4.31669 7.29175 4.31669 6.94175 4.55002 6.70841C4.78335 6.47508 5.13335 6.47508 5.36669 6.70841L6.24169 7.58341L8.63335 5.19175C8.86669 4.95841 9.21669 4.95841 9.45002 5.19175C9.68335 5.42508 9.68335 5.77508 9.45002 6.00841Z" fill="#6FCF97"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>