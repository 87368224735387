<template>
    <div class="biography">
        <div class="bio-title">
            <Biography />
            Biografia
        </div>
        <div class="bio">
            <p>{{ bio }}</p>
        </div>
    </div>
</template>

<script>
// import { onMounted, ref } from "vue";
import Biography from "../../../../assets/IconsComponent/Biography.vue";

export default {
    props: ["bio"],
    components: {
        Biography,
    },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };

        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.biography {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
    border-radius: 12px;
    gap: 14px;
    padding: 12px 40px 16px 12px;

    .bio-title {
        display: flex;
        align-items: center;
        gap: 6px;
        color: var(--gray-2, #4F4F4F);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; 
    }

    p {
        color: var(--gray-3, #828282);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;


        max-width: 100%;
        overflow: hidden; // Removendo barra de rolagem
        text-overflow: ellipsis; // Adicionando "..." ao final
        display: -webkit-box;
        -webkit-line-clamp: 3; // Quantidade de linhas
        -webkit-box-orient: vertical;
    }
}

h1 {
    margin: 0;
    padding: 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #BDBDBD;
}
</style>