<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M13.0667 2.40005C12.9334 2.26672 12.7334 2.20005 12.5334 2.26672C11.0667 2.60005 9.60002 2.26672 8.40002 1.40005C8.20002 1.26672 7.86669 1.26672 7.66669 1.40005C6.40002 2.26672 4.93335 2.60005 3.46669 2.26672C3.13335 2.20005 2.73335 2.46672 2.66669 2.80005C2.66669 2.86672 2.66669 2.86672 2.66669 2.93338V7.93338C2.66669 9.86671 3.60002 11.6667 5.20002 12.8L7.66669 14.5334C7.86669 14.6667 8.20002 14.6667 8.46669 14.5334L10.9334 12.8C12.5334 11.6667 13.4667 9.86671 13.4667 7.93338V2.93338C13.3334 2.73338 13.2667 2.53338 13.0667 2.40005ZM9.33335 8.66672H8.66669V9.33338C8.66669 9.73338 8.40002 10 8.00002 10C7.60002 10 7.33335 9.73338 7.33335 9.33338V8.66672H6.66669C6.26669 8.66672 6.00002 8.40005 6.00002 8.00005C6.00002 7.60005 6.26669 7.33338 6.66669 7.33338H7.33335V6.66672C7.33335 6.26672 7.60002 6.00005 8.00002 6.00005C8.40002 6.00005 8.66669 6.26672 8.66669 6.66672V7.33338H9.33335C9.73335 7.33338 10 7.60005 10 8.00005C10 8.40005 9.73335 8.66672 9.33335 8.66672Z" fill="#4F4F4F"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>